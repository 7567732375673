<template>
  <div class="row">
    <el-empty v-if="isFalse" :description="errorMessage" class="mx-auto text-center"></el-empty>
    <div class="col-xl-12" v-else>
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                <el-input type="text" size="medium" v-model="form.name" class=" mt-3"
                          placeholder="Tìm tên, sđt học viên" autocomplete="false" ></el-input>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-select v-model="form.type" clearable class="w-100 mt-3" placeholder="Chọn loại tư vấn">
                  <el-option
                      v-for="item in advise_types"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                <el-date-picker
                    class="w-100"
                    v-model="form.startDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="pickStart"
                    :picker-options="pickerStartOptions"
                    placeholder="Từ ngày">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                <el-date-picker
                    class="w-100"
                    v-model="form.endDate"
                    type="date"
                    ref="picker"
                    :onPick="pickEnd"
                    format="yyyy-MM-dd"
                    @change="pickEnd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerEndOptions"
                    placeholder="Đến ngày">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading.search"
                        class="btn btn-primary w-100 mt-3"
                        @click="find">
                  <i v-bind:class="[loading.search ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>
            </el-row>
              <div class="panel-tag mb-3 mt-3">
                  <h4 v-if="this.typeDisplay === 1">Doanh số hợp đồng tư vấn: <b class="text-danger">{{total | formatVND}}</b></h4>
                  <h4 v-else >Khách hàng tư vấn: <b class="text-danger">{{total}}</b></h4>
              </div>
            <el-table
                :data="tableData"
                stripe
                v-loading="loading.table"
                border
                class="table-hover mb-5 mt-3 table-responsive-lg"
                style="width: 100%">
              <el-table-column label="#" type="index"  width="50" :index="indexMethod">
              </el-table-column>
              <el-table-column
                  label="Mã hóa đơn" >
                <template slot-scope="scope">
                  {{ scope.row.contract_student_amount_id }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Họ và tên"
              >
                <!--eslint-disable-->
                <template slot-scope="scope">
                  <router-link :to="{name: 'customer-edit', params : {id : scope.row.id}}"  style="padding-left: 10px; color: #0dad0d; text-decoration: underline; cursor: pointer;">
                    {{ scope.row.name }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                  label="Mã học viên" >
                <template slot-scope="scope">
                  {{ scope.row.user_id + 100000 }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="phone"
                  label="Số điện thoại">
                <template slot-scope="scope">
                  {{ scope.row.phone }}
                </template>
              </el-table-column>
              <el-table-column
                  :label="typeAdvise" >
                <template slot-scope="scope">
                  {{scope.row.dated_paid ? scope.row.dated_paid : scope.row.ngay_hen}}
                </template>
              </el-table-column>
              <el-table-column
                  label="Đã nộp"
                  class="">
                <template slot-scope="scope">
                  {{ scope.row.amount ? scope.row.amount : scope.row.so_tien | formatVND }}
                </template>
              </el-table-column>
            </el-table>
            <div class="edutalk-paging" >
              <div class="block">
                <!--eslint-disable-->
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="paging.current_page"
                    :page-size="paging.per_page"
                    layout="prev, pager, next"
                    :total="paging.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   Modal nhân sự nghỉ việc   -->
    <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogFormDismissal">
      <el-row :gutter="10" class="pr-2 pl-2">
        <h5>Bạn có muốn cho nhân sự: <b>{{ humanResource.name }}</b></h5>
        <h5>Mã nhân sự: <b>{{ humanResource.code }}</b></h5>
        <div class="form-row">
          <div class="col-md-12 mt-3">
            <label class="form-label">Thôi việc từ tháng</label>
            <edutalk-select v-model="form.month" class="w-100"  placeholder="Chọn tháng nghỉ việc">
              <edutalk-option
                  v-for="item in optionMonth"
                  :key="item"
                  :label="item"
                  :value="item"
              >
              </edutalk-option>
            </edutalk-select>
          </div>
          <div class="col-md-12 mt-3">
            <input v-model="form.reason_lay_off" disabled type="text" class="form-control" id="address"
                   placeholder="Nhập địa chỉ thường trú">
          </div>
        </div>
        <button class="btn btn-outline-default float-right mt-5 mr-3" @click="dialogFormDismissal = false"> Hủy </button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import moment from 'moment-timezone';
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {DOANH_SO_TU_VAN} from "@/core/services/store/advise.module";
const _ = deepdash(lodash);

export default {
  name: "doanh-so-tu-van",
  data() {
    return {
      moment : moment,
      form: {
        name: null,
        type: 1,
        startDate: null,
        endDate: null
      },
      pickerStartOptions: {
        disabledDate: this.disabledStartDate
      },
      pickerEndOptions: {
        disabledDate: this.disabledEndDate
      },
      loading: {
        table: false,
        search: false,
        save: false,
      },
      tableData: [],
      currentDate : new Date(),
      typeAdvise: '',
      paging: {
        current_page: 1,
        per_page: 50,
        total: 0,
      },
      humanResource: [],
      optionMonth: [],
      dialogFormVisible: false,
      dialogFormDismissal: false,
      dialog: {
        title : null,
        isEdit: false,
        isVerify: false,
      },
      isUpdate: false,
      isConfirm: false,
      typeDisplay: false,
      advise_types: [{id : 1 ,name : 'Hợp đồng tư vấn'},{id : 2, name: 'Khách hàng tư vấn'}],
      total: 0,
      isFalse : false,
      errorMessage : ''
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tư vấn" },
      {title: "Doanh số tư vấn", icon: 'far fa-user'}
    ]);
    let date = new Date();
    this.form.startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
    this.form.endDate = this.$moment().format('YYYY-MM-DD');
    this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.toDate = new Date();
    this.querySever();
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    closeDialog() {
      this.dialogFormVisible = false;
    },
    handleCurrentChange(val) {
      this.querySever({page: val});
    },
    indexMethod(index) {
      return 10 * (this.paging.current_page - 1) + (index + 1);
    },
    coverVerify(item) {
      if (item.profile.status_verify === 1) {
        return 'Xác minh cho nhân sự ngay'
      }
      if (item.profile.status_verify === 2) {
        return 'Đã xác minh danh tính'
      }
      if (item.profile.status_verify === 3) {
        return 'Đã bị từ chối xác minh'
      }
      return ''
    },
    find() {
      this.paging.current_page = 1;
      this.querySever();
    },
    querySever(customProperties) {
      this.loading.table = true;
      this.loading.search = true;
      let params = this.mergeParams(customProperties);
      this.currentPage = 1;
      this.$store.dispatch(DOANH_SO_TU_VAN, params)
          .then((response) => {
            if (response.status === 422) {
              this.isFalse = true;
              this.errorMessage = response.data.message;
            } else {
              this.typeAdvise = this.form.type === 2 ?  'Thời gian tư vấn' :  'Thời gian nộp tiền';
              this.tableData = response.data.data;
              this.paging.per_page = parseInt(response.data.per_page);
              this.paging.total = response.data.total;
              this.renderTypeTotal(response.data.data);
            }
            this.loading.table = false;
            this.loading.search = false;
          })
    },
    mergeParams(customProperties) {
      let params = {
        page: this.paging.current_page,
        per_page: this.paging.per_page,
      };
      if (this.form.name) {
        params = _.merge(params, {name: this.form.name })
      }
      if (this.form.type) {
        params = _.merge(params, {type: this.form.type })
      }
      if (this.form.startDate) {
        params = _.merge(params, {startDate: this.form.startDate})
      }
      if (this.form.endDate) {
        params = _.merge(params, {endDate: this.form.endDate })
      }
      params = _.merge(params, customProperties);
      return params;
    },
    /* date picker methods */
    pickStart(date) {
      this.fromDate = null;
      if (date) {
        this.fromDate = new Date(date);
      }
    },
    pickEnd(date) {
      this.toDate = null;
      if (date) {
        this.toDate = new Date(date);
      }
    },
    disabledStartDate(date) {
      if (this.toDate) {
        return this.toDate < date
      }
      return date > new Date();
    },
    disabledEndDate(date) {
      if (this.fromDate) {
        return this.fromDate > date || date > new Date();
      }
      return date > new Date();
    },
    renderTypeTotal(data) {
      this.typeDisplay = this.form.type;
      if(data && data.length > 0){
        this.total =  this.typeDisplay === 1 ? data[0].total_amount ?? 0 : data[0].totalCount ?? 0;
      }else{
        this.total = 0;
      }
    }
  },
}
</script>
